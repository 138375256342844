<template>
  <div class="all">
    <!--    顶部Banner-->
    <div class="topTitle">
      <div class="title">
        <!--        顶部logo-->
        <div class="imgLogo" @click="home"><img src="../../assets/img/logo2.png" class="home">
          <p>环宇建筑科技股份有限公司</p>
        </div>
      </div>
      <div class="cont">
        <div class="login">
          <span>忘记密码/修改密码</span>
          <div class="input_two">
            <div class="input_phone"><img src="../../assets/img/phone.png">
              <el-input v-model="phone" class="input_i" maxlength="11" placeholder="请输入您的手机号"
                        @change="phone_gz"></el-input>
            </div>
            <div class="input_phone"><img src="../../assets/img/code.png">
              <el-input v-model="Vcode" class="input_p" maxlength="5" placeholder="请输入您的验证码"></el-input>
              <span v-show="show" :datafld="!show" class="code_get" @click="code_time">获取验证码</span>
              <span v-show="!show" class="code_get">{{ code }}s后获取</span>
            </div>
            <!--            <router-link :to="{name:'update_pwd'}" class="update_pwd"> 忘记密码</router-link>-->
          </div>
          <div class="login_d" @click="new_pwd">下一步</div>
          <div class="tab_but">
            <router-link :to="{name:'login'}" class="text_login" style="padding: 0px 20px"> 账号登录</router-link>
            <router-link :to="{name:'quick_login'}" class="text_login"><p>手机快捷登录</p></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { isPhoneCli, VtPhone } from '@/api/api'

const TIME_COUNT = 60 // 验证码倒计时
export default {
  name: 'update_pwd',
  data () {
    return {
      phone: '',
      Vcode: '',
      show: true,
      code: '',
      timer: null,
      message: ''
    }
  },
  methods: {
    // 手机号验证是否正确
    phone_gz () {
      if (!/^1[0-9]{10}$/.test(this.phone)) {
        this.phone = ''
        this.$message.error('请输入正确的电话号码')
      }
    },
    // 验证码倒计时
    code_time () {
      if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.phone) || this.phone === '') {
        this.$message.error('请输入正确的手机号码！')
      } else {
        if (!this.timer) {
          this.code = TIME_COUNT
          this.show = false
          axios.defaults.headers.token = localStorage.getItem('token')
          VtPhone({
            method: 'authCode',
            phone: this.phone
          }, res => {
            this.$message.success('验证码已发送至您的手机！')
            this.message = res.data.data
          })
          this.timer = setInterval(() => {
            if (this.code > 0 && this.code <= TIME_COUNT) {
              this.code--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      }
    },
    // 点击下一步进入修改界面
    new_pwd () {
      isPhoneCli({
        method: 'isPhone',
        phone: this.phone
      }, res => {
        if (res.data.data === '0') {
          this.$message.error('手机号未注册，请先注册')
        } else {
          // 跳转更新密码一下步
          if (this.phone === '' || this.phone === null) {
            this.$message.error('手机号码不能为空！')
          } else if (!/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/.test(this.phone)) {
            this.$message.error('请输入正确的手机号码！')
          } else if (this.Vcode === '' || this.Vcode === null) {
            this.$message.error('请输入您的验证码！')
          } else if (this.message !== this.Vcode) {
            this.$message.error('请您输入正确的验证码！')
          } else {
            this.$router.push({
              name: 'new_pwd',
              params: { phone: this.phone }
            })
          }
        }
      })
    },
    home () {
      this.$router.replace('/')
    }
  }

}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.all {
  width: 100%;
  height: 965px;
  margin: 0 auto;
}

/*顶部title*/
.topTitle {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: url("../../assets/img/login_bk.png");
  background-size: 100% 100%;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}
.home {
  cursor: pointer;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
  margin-bottom: 90px;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.cont {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  width: 100%;
  height: 80%;
  margin: 0 auto;
  /*background-color: blueviolet;*/
  /*text-align: center;*/
}

/*登录框*/
.login {
  margin: 0px auto;
  width: 400px;
  height: 390px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
}

.login > span {
  font-size: 18px;
  color: #323333;
  line-height: 88px;
  letter-spacing: 1px;
}

/*input框*/
.input_phone > img {
  /*position: absolute;*/
  margin-left: -20px;
  margin-top: 30px;
  width: 20px;
  height: 20px;
}

.input_i {
  width: 300px;
  margin-left: 10px;
  margin-top: 20px;
}

.input_p {
  width: 300px;
  margin-left: 14px;
  margin-top: 20px;
}

/*忘记密码*/
.update_pwd {
  font-size: 14px;
  color: #EA5D5C;
  line-height: 40px;
  margin-left: 270px;
  cursor: pointer;
}

/*登录按钮*/
.login_d {
  width: 320px;
  height: 50px;
  background-color: #10539B;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  color: white;
  margin-left: 40px;
  border-radius: 4px;
  margin-top: 19px;
  letter-spacing: 1px;
  cursor: pointer;
}

/*跳转*/
.tab_but {
  display: flex;
  justify-content: space-between;
  padding: 19px 30px;
}

.text_login {
  color: #666666;
  font-size: 14px;
  cursor: pointer;
}

.code_get {
  position: absolute;
  width: 140px;
  height: 37px;
  text-align: center;
  /*background-color: #8493ad;*/
  line-height: 37px;
  margin-left: -120px;
  margin-top: 21px;
  cursor: pointer;
  color: #0D5091;
}
</style>
